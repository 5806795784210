import React, { useCallback, useEffect, useMemo, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Input, Select, Textarea } from '../../../../../components/Form'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'
import { FormContainer } from './styles'
import { dateInputFormat, PROSPECT_STATUS } from 'common/constants'
import { Date as DatePicker } from 'components/Form/date'
import { IMaskInput } from 'react-imask'
import moment from 'moment'
import { Loading } from 'components/Loading'
import getEnglishStatus from 'utlis/getEnglishStatus'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}

type PartnerData = {
  id?: number
  files?: any[]
  status: string
}

type TypesFormProps = {
  isOpenInModal?: IsOpenInModalProps
  initialValues?: PartnerData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}
export const FormHistoryProspect = ({
  isOpenInModal,
  initialValues,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const { activeLoading, disableLoading } = useLoading()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const [scheduledDate, setScheduledDate] = useState<Date>()
  const [statusValue, setStatusValue] = useState('')
  const [defaultValues, setDefaultValues] = useState<PartnerData>()
  const indexFiles = useMemo(() => [0, 1, 2, 3], [])
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    if (initialValues) {
      console.log(initialValues)
      setDefaultValues({
        ...initialValues,
        status: getEnglishStatus({
          status: initialValues?.status
        })
      })
    }
  }, [initialValues])

  const handleNameOfFile = useCallback((item: { filename: string }) => {
    let filename = item?.filename
    const indexTrace = filename.indexOf('-') + 1
    filename = filename.slice(indexTrace, filename.length)
    return filename
  }, [])

  const handleRemoveFile = useCallback(
    async (item: { filename: string }) => {
      const id = initialValues?.id
      setIsLoading(true)
      try {
        const responseRemoveFile = await api.put(
          `/commercial/prospectHistories/removeFile/${id}`,
          {
            filename: item.filename
          }
        )
        setDefaultValues({ ...responseRemoveFile.data })
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        addToast({
          type: 'error',
          title: 'Erro ao atualizar o registro',
          description:
            'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
        })
      }
    },
    [addToast, initialValues?.id]
  )

  const onSubmitForm = async (data: any) => {
    const id = initialValues?.id
    data.prospect_id = isOpenInModal?.idParent
    const formData = new FormData()
    Object.entries(data).forEach(([key, value]: any) => {
      if (key === 'files' && value[0]) {
        value.forEach((item: any) => {
          if (typeof item !== 'string' && item?.[0]) {
            formData.append(key, item[0])
          }
        })
        return
      }
      if (key === 'scheduled_at') {
        if (scheduledDate) {
          formData.append(key, moment(scheduledDate).format('YYYY-MM-DD'))
        }
        return
      }

      formData.append(key, value)
    })
    try {
      if (typeForm === 'create') {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          activeLoading()
          try {
            await api.post('/commercial/prospectHistories/create', formData)
            handleOnClose()
            disableLoading()
            updateDataTable()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
          } catch (error) {
            console.log(error)
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o registro',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            handleOnClose()
            disableLoading()
            updateDataTable()
          }
        } else {
          try {
            activeLoading()
            await api.post(apiCreate(), formData)
            disableLoading()
            updateDataTable()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
            history.push(nameActions.read.to)
          } catch (error) {
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o registro',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            disableLoading()
            updateDataTable()
          }
        }
      } else {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal

          try {
            activeLoading()
            await api.put(
              `/commercial/prospectHistories/update/${id}`,
              formData
            )
            updateDataTable()
            disableLoading()
            handleOnClose()
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (error) {
            disableLoading()
            handleOnClose()
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        } else {
          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), formData)
            updateDataTable()
            disableLoading()
            history.push(nameActions.read.to)
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (error) {
            history.push(nameActions.read.to)
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
        if (isOpenInModal) isOpenInModal.handleOnClose()
      }
    }
  }
  return (
    <>
      <Loading isActive={isLoading} />
      <FormContainer>
        <Form onSubmit={onSubmitForm} defaultValues={defaultValues}>
          <div className="form">
            <div className="row">
              <Select
                name="status"
                className="col-6"
                label="Status"
                id="status"
                options={PROSPECT_STATUS}
                rules={{ required: true }}
                value={statusValue}
                blank
                controlled
              />
              <DatePicker
                name="scheduled_at"
                className="col-6"
                label="Data de agendamento"
                placeholderText="DD/MM/AAAA"
                dateFormat="dd/MM/yyyy"
                selected={scheduledDate}
                customInput={
                  <IMaskInput
                    mask={Date}
                    pattern={'d/m/Y'}
                    format={date => {
                      return moment(date).format(dateInputFormat)
                    }}
                    parse={value => {
                      return moment(value, dateInputFormat).toDate()
                    }}
                  />
                }
                onChange={date => {
                  setScheduledDate(date)
                  setStatusValue('in_scheduling')
                }}
                controlled
              />
            </div>

            <div className="row mb-default">
              <Textarea
                className="col-12"
                label="Descrição"
                name="description"
                style={{ minHeight: 150 }}
              />
            </div>
            <div className="row mb-default">
              {indexFiles.map(item => (
                <div className="col-6" key={item}>
                  <Input
                    className=""
                    label={'Arquivo ' + (item + 1)}
                    name={'files.' + item}
                    type="file"
                    disabled={!!defaultValues?.files?.[item]}
                  />
                  {defaultValues?.files?.[item] && (
                    <div className="d-flex align-items-center">
                      <p className="col-form-label fw-bold fs-6 d-flex me-2 ">
                        {handleNameOfFile(defaultValues?.files?.[item])}
                      </p>
                      <span
                        onClick={() =>
                          handleRemoveFile(defaultValues?.files?.[item])
                        }
                        className="fa fa-remove text-danger"
                        style={{ fontSize: 16, cursor: 'pointer' }}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="row mb-default"></div>

            <div className="card-footer d-flex justify-content-end px-0 mt-5">
              <button type="submit" className="btn btn-primary">
                Salvar
              </button>
            </div>
          </div>
        </Form>
      </FormContainer>
    </>
  )
}
