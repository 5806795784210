import React from 'react'
import PrivateRoutes from '../Route'
import List from '../../pages/Commercial/QueryBatch/List'
import Create from '../../pages/Commercial/QueryBatch/Create'
import CreateImport from '../../pages/Commercial/QueryBatch/CreateImport'
import View from '../../pages/Commercial/QueryBatch/View'
import { CustomSwitch } from '../../components/CustomSwitch'
import Update from '../../pages/Commercial/QueryBatch/Update'
import Reconciliation from '../../pages/Commercial/ReconciliationPartners/Create'

export const QueryBatchsRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoutes path="/commercial/querybatchs" exact component={List} />
    <PrivateRoutes
      path="/commercial/querybatchs/create"
      exact
      component={Create}
    />
    <PrivateRoutes
      path="/commercial/querybatchs/import/create"
      exact
      component={CreateImport}
    />
    <PrivateRoutes
      path="/commercial/querybatchs/view/:id"
      exact
      component={View}
    />
    <PrivateRoutes
      path="/commercial/querybatchs/update/:id"
      exact
      component={Update}
    />
    <PrivateRoutes
      path="/commercial/querybatchs/reconciliationPartners"
      exact
      component={Reconciliation}
    />
  </CustomSwitch>
)
