import React, { useEffect, useMemo, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Input, Select } from '../../../../../components/Form'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'
import { useForm } from 'react-hook-form'
import generateOptions from 'utlis/generateOptions'
import { useAuth } from 'hooks/auth'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}

type AgreementGroupData = {
  id?: string
  name?: string
  cnpj?: string
  allowed_queries?: string[]
}

type TypesFormProps = {
  isOpenInModal?: false | IsOpenInModalProps
  initialValues?: AgreementGroupData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}
export const FormAgreementGroup = ({
  isOpenInModal,
  initialValues,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const [defaultValues, setDefaultValues] = useState<AgreementGroupData>()
  const { user } = useAuth()
  const reactHookFormAllowedQueries = useForm()
  const apiNameOptions = useMemo(() => {
    const options = generateOptions({
      user,
      options: [
        {
          name: 'Credcesta',
          value: 'credcesta'
        },
        {
          name: 'Titulo de capitalização Cap Já',
          value: 'credcesta_card'
        },
        {
          name: 'Santander correntista',
          value: 'santander_correntista'
        },
        {
          name: 'Santander margem',
          value: 'santander_margem'
        },
        {
          name: 'Lemit',
          value: 'lemit'
        },
        {
          name: 'QI',
          value: 'qi'
        }
      ]
    })
    return options.sort((currentItem, nextItem) =>
      currentItem.name.localeCompare(nextItem.name)
    )
  }, [user])

  useEffect(() => {
    if (initialValues) {
      setDefaultValues({
        ...initialValues
      })
      if (initialValues?.allowed_queries?.length) {
        initialValues?.allowed_queries?.forEach(item => {
          reactHookFormAllowedQueries.setValue(item, true)
        })
      }
    }
  }, [initialValues, reactHookFormAllowedQueries])

  const { activeLoading, disableLoading } = useLoading()

  const onSubmitForm = async (data: AgreementGroupData) => {
    const id = initialValues?.idUpdate
    const allowedQueries = reactHookFormAllowedQueries.getValues()

    console.log(allowedQueries)

    const objectToArray = Object.entries(allowedQueries)
      .map(([key, value]) => ({
        key,
        value: !!value
      }))
      .filter(item => item.value)

    if (!objectToArray.length) {
      addToast({
        title: 'Dados não preenchidos',
        description: 'Selecione pelo menos uma consulta',
        type: 'info'
      })
      return
    }

    data.allowed_queries = objectToArray.map(item => item.key)

    try {
      if (typeForm === 'create') {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          activeLoading()
          const dataCreate = {
            ...data
          }
          try {
            await api.post(apiCreate(), dataCreate)
            handleOnClose()
            disableLoading()
            updateDataTable()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
          } catch (error) {
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o registro',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            handleOnClose()
            disableLoading()
            updateDataTable()
          }
        } else {
          const dataCreate = {
            ...data
          }
          try {
            activeLoading()
            await api.post(apiCreate(), dataCreate)
            disableLoading()
            updateDataTable()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
            history.push(nameActions.read.to)
          } catch (error) {
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o registro',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            disableLoading()
            updateDataTable()
          }
        }
      } else {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          const dataUpdate = {
            ...data
          }

          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), dataUpdate)
            updateDataTable()
            disableLoading()
            handleOnClose()
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (error) {
            disableLoading()
            handleOnClose()
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        } else {
          const dataUpdate = {
            ...data
          }

          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), dataUpdate)
            updateDataTable()
            disableLoading()
            history.push(nameActions.read.to)
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (error) {
            history.push(nameActions.read.to)
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
        if (isOpenInModal) isOpenInModal.handleOnClose()
      }
    }
  }

  return (
    <Form onSubmit={onSubmitForm} defaultValues={defaultValues}>
      <div className="form">
        <div className="row mb-5">
          <Input
            className="col-3"
            name="agreement_number"
            label="Código"
            rules={{ required: true }}
          />
          <Input
            className="col-6"
            name="name"
            label="Nome"
            rules={{ required: true }}
          />
          <Input
            name="cnpj"
            label="CNPJ"
            className="col-3"
            mask={{
              mask: '99.999.999/9999-99'
            }}
            controlled
            rules={{ required: false }}
          />
        </div>
        <div className="row mb-5">
          <Select
            className="col-3"
            name="type"
            label="Tipo"
            rules={{ required: true }}
            blank
            options={[
              {
                name: 'Privado',
                value: 'private'
              },
              {
                name: 'Publico',
                value: 'public'
              }
            ]}
          />
        </div>

        <div className="row mb-5">
          <h3>Consultas permitidas</h3>
          {apiNameOptions.map(({ name, value }) => (
            <div style={{ display: 'flex', alignItems: 'center' }} key={value}>
              <input
                type="checkbox"
                {...reactHookFormAllowedQueries.register(`allowed_${value}`, {
                  required: false
                })}
                onChange={event => {
                  reactHookFormAllowedQueries.setValue(
                    `allowed_${value}`,
                    event.target.checked
                  )
                }}
                checked={reactHookFormAllowedQueries.watch(`allowed_${value}`)}
                style={{ cursor: 'pointer' }}
              />
              <label
                htmlFor={`allowed_${value}`}
                style={{ marginLeft: '0.5rem' }}
              >
                {name}
              </label>
            </div>
          ))}
        </div>
        <div className="card-footer d-flex justify-content-end py-6 px-9">
          <button type="submit" className="btn btn-primary">
            Salvar
          </button>
        </div>
      </div>
    </Form>
  )
}
