import { PROSPECT_STATUS } from 'common/constants'

export default function getEnglishStatus({
  status
}: {
  status: string
}): string {
  let newStatus = PROSPECT_STATUS.find(
    item => item.name.toLowerCase() === status?.toLowerCase()
  )?.value
  if (!newStatus) {
    newStatus = status
  }
  return newStatus
}
