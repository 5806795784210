import {
  LEMIT_COLUMN_NAME_DDD,
  LEMIT_COLUMN_NAME_NUMBER,
  LEMIT_COLUMN_NAME_WHATSAPP
} from 'common/constants/Commercial/Prospects'
import CustomDataTable from 'components/CustomDataTable'
import React from 'react'
import { cellPhoneMask } from 'utlis/mask'

// Tipos para os dados de entrada (exemplo, você pode adaptar conforme necessário)
type LeMitDataItem = {
  title: React.ReactNode
  value: React.ReactNode
  type: string
  child: any[]
}

const LemitDataDisplay: React.FC<{ lemitData?: LeMitDataItem[] }> = ({
  lemitData
}) => {
  const serializedKey = (value: string): string => value.split('_').join(' ')

  return (
    <div className="mt-5">
      {lemitData?.map?.(
        (item: {
          child: any[]
          title:
            | boolean
            | React.ReactChild
            | React.ReactFragment
            | React.ReactPortal
          type: string
          value:
            | boolean
            | React.ReactChild
            | React.ReactFragment
            | React.ReactPortal
        }) => {
          const hasSubItems = !!item?.child?.length
          const subItems = item?.child
          const subItemsIsObject = item?.child?.every(
            (subItem: any) => typeof subItem === 'object'
          )
          const subHeaders = hasSubItems
            ? Object.keys(subItems[0])
                .filter(
                  key =>
                    key.toLocaleLowerCase() !== LEMIT_COLUMN_NAME_DDD &&
                    key.toLocaleLowerCase() !== 'donotdisturb'
                )
                .map(key => {
                  if (key.toLocaleLowerCase() === LEMIT_COLUMN_NAME_NUMBER) {
                    if (item?.child[0]?.[LEMIT_COLUMN_NAME_WHATSAPP]) {
                      return {
                        name: serializedKey(key),
                        field: key,
                        sortable: true,
                        custom: true
                      }
                    }
                  }
                  return {
                    name: serializedKey(key),
                    field: key,
                    sortable: true
                  }
                })
            : []
          return (
            <div key={Math.random() * 1000 + 1} className="row mb-2">
              <h1 style={{ textTransform: 'capitalize' }}>{item?.title}</h1>
              {hasSubItems ? (
                item.type === 'array' ? (
                  subItemsIsObject ? (
                    <CustomDataTable
                      customItems={item?.child}
                      headers={subHeaders}
                      customHeaders={[
                        {
                          name: 'Número',
                          field: 'numero',
                          sortable: true,
                          element: item => {
                            const fullNumber = `${item?.[LEMIT_COLUMN_NAME_DDD]}${item?.[LEMIT_COLUMN_NAME_NUMBER]}`
                            return (
                              <p>
                                {item?.doNotDisturb && (
                                  <button
                                    className="btn py-0"
                                    style={{ cursor: 'default' }}
                                  >
                                    <span className="fa-solid fa-ban fa-xl text-danger"></span>
                                  </button>
                                )}
                                {cellPhoneMask(fullNumber)}

                                {!!item?.[LEMIT_COLUMN_NAME_WHATSAPP] && (
                                  <button
                                    className="btn py-0"
                                    disabled={item?.doNotDisturb}
                                    onClick={() => {
                                      const a = document.createElement('a')
                                      a.href = `https://api.whatsapp.com/send?phone=${fullNumber}&text=`
                                      a.target = '_blank'
                                      a.click()
                                      a.remove()
                                    }}
                                  >
                                    <span
                                      className={`fab fa-whatsapp fa-xl ${
                                        item?.doNotDisturb
                                          ? ''
                                          : ' text-primary'
                                      }`}
                                    ></span>
                                  </button>
                                )}
                              </p>
                            )
                          }
                        }
                      ]}
                    />
                  ) : (
                    item?.child?.map((subItem: any) => {
                      return (
                        <div className="col" key={Math.random() * 1000 + 1}>
                          <p
                            className="fw-bolder fs-6 text-gray-800"
                            style={{
                              whiteSpace: 'nowrap',
                              textTransform: 'capitalize'
                            }}
                          >
                            {subItem.value}
                          </p>
                        </div>
                      )
                    })
                  )
                ) : (
                  item?.child?.map((subItem: any) => {
                    return (
                      <div className="col" key={Math.random() * 1000 + 1}>
                        <label
                          className=" fw-bold fs-6"
                          style={{ textTransform: 'capitalize' }}
                        >
                          {subItem.title}
                        </label>
                        <p
                          className="fw-bolder fs-6 text-gray-800"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          {subItem.value}
                        </p>
                      </div>
                    )
                  })
                )
              ) : (
                <p
                  className="fw-bolder fs-6 text-gray-800"
                  style={{ textTransform: 'capitalize' }}
                >
                  {item?.value}
                </p>
              )}
            </div>
          )
        }
      )}
    </div>
  )
}

export default LemitDataDisplay
