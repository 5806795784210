import React, { useCallback, useRef, useState } from 'react'
import { FormHandles } from '@unform/core'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../hooks/auth'
import { useToast } from '../../hooks/toast'
import getValidationErros from '../../utlis/getValidationErros'
import logoImg from '../../assets/logo-cedibra.png'
import { Contanier, Content } from './styles'
import Form, { Input } from '../../components/Form'
import ReCAPTCHA from 'react-google-recaptcha'

import api from 'services/api'
import { Loading } from 'components/Loading'

interface SingInFormData {
  username: string
  password: string
}

const SignUp = (): JSX.Element => {
  const formRef = useRef<FormHandles>(null)
  const { signIn, backupHistory, setHistory } = useAuth()
  const { addToast } = useToast()
  const history = useHistory()
  const [captchaToken, setCaptchaToken] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = useCallback(
    async (data: SingInFormData) => {
      setIsLoading(true)
      if (!captchaToken) {
        setError('Por favor, resolva o CAPTCHA.')
        return
      }

      try {
        await api.post('/api/validate-captcha', {
          token: captchaToken
        })
        setIsLoading(false)
      } catch (error) {
        setCaptchaToken(null)
        setError('Captcha invalido')
        setIsLoading(false)
        addToast({
          type: 'error',
          title: 'Captcha invalido',
          description: 'Tente novamente marcar o captcha'
        })
        return
      }

      try {
        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          username: Yup.string().required('Usuário obrigatório'),
          password: Yup.string().required('Senha obrigatório')
        })

        await schema.validate(data, {
          abortEarly: false
        })

        await signIn({
          username: data.username,
          password: data.password
        })

        // history.push(backupHistory || '/dashboard')
        const a = document.createElement('a')
        a.href = backupHistory || '/dashboard'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        setHistory('')
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const erros = getValidationErros(err)
          formRef.current?.setErrors(erros)
          return
        }
        if (
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes('this partner is not allowed to login')
        ) {
          addToast({
            type: 'error',
            title: 'Ocorreu algum problema',
            description:
              'Ocorreu algum problema com seu login. Contate o seu gestor para saber mais'
          })
          return
        }
        if (
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes('user already has an active session')
        ) {
          addToast({
            type: 'error',
            title: 'Acesso não autorizado',
            description: 'Acesso negado porque o usuário já tem sessão ativa'
          })
          return
        }
        if (
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes(
              'acesso não autorizado, por favor entre em contato com o comercial'
            )
        ) {
          addToast({
            type: 'error',
            title: 'Ocorreu algum problema',
            description:
              'Acesso não autorizado, por favor entre em contato com o comercial'
          })
          return
        }
        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Confira seus dados de acesso.'
        })
      } finally {
        setIsLoading(false)
      }
    },

    [captchaToken, signIn, backupHistory, setHistory, addToast]
  )

  // Função chamada quando o reCAPTCHA é resolvido
  const handleCaptchaChange = (token: any) => {
    setCaptchaToken(token)
    setError('') // Limpa o erro do CAPTCHA, se existir
  }

  return (
    <Contanier>
      {isLoading && <Loading isActive />}
      <Content>
        <div className="logo">
          <a href="#">
            <img className="logo-light" src={logoImg} alt="" width="222" />
          </a>
        </div>
        <div className="content">
          <Form onSubmit={onSubmit} className="form">
            <h3 className="form-title font-dark">Autenticação</h3>
            <Input
              className="mb-3"
              autoComplete="off"
              placeholder="Usuário"
              name="username"
              rules={{ required: true }}
            />
            <Input
              type="password"
              autoComplete="off"
              placeholder="Senha"
              name="password"
              rules={{ required: true }}
            />
            <div className="container-re-captcha">
              <div style={{ margin: '10px 0' }}>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_GOOGLE_SITE_KEY}
                  onChange={handleCaptchaChange}
                />
                {error && <p style={{ color: 'red' }}>{error}</p>}
              </div>
            </div>
            <div
              className="form-actions"
              style={{ display: 'flex', justifyContent: 'center', padding: 0 }}
            >
              <button
                type="submit"
                className="btn btn-dark"
                disabled={!captchaToken}
              >
                Entrar
              </button>
            </div>
          </Form>
        </div>
      </Content>
    </Contanier>
  )
}
export default SignUp
