import 'core-js/stable'
import 'regenerator-runtime/runtime'
import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'

import GlobalStyle from './styles/global'
import Routes from './routes'
import AppProvider from './hooks'

import './assets/sass/plugins.scss'
import './assets/sass/style.scss'
import './assets/sass/style.react.scss'
import { LayoutProvider, PageDataProvider } from './assets/layout/core'
import { MasterInit } from './assets/layout/MasterInit'

const App: React.FC = () => {
  const [isMouseOutside, setIsMouseOutside] = useState(false)
  const [isScreenDimmed, setIsScreenDimmed] = useState(false)

  // useEffect(() => {
  //   // Função para escurecer a tela quando o mouse sai da página
  //   const handleMouseLeave = (e: any) => {
  //     if (
  //       e.clientY <= 0 || // Saída pela parte superior
  //       e.clientX <= 0 || // Saída pela lateral esquerda
  //       e.clientX >= window.innerWidth || // Saída pela lateral direita
  //       e.clientY >= window.innerHeight // Saída pela parte inferior
  //     ) {
  //       setIsScreenDimmed(true)
  //     }
  //   }

  //   // Função para reativar a tela quando o mouse entra na página
  //   const handleMouseEnter = () => {
  //     setIsScreenDimmed(false)
  //   }

  //   // Função para escurecer a tela quando a janela perde o foco
  //   const handleWindowBlur = () => {
  //     setIsScreenDimmed(true)
  //   }

  //   // Função para reativar a tela quando a janela ganha o foco
  //   const handleWindowFocus = () => {
  //     setIsScreenDimmed(false)
  //   }

  //   // Adiciona os eventos de mouse e foco
  //   document.addEventListener('mouseleave', handleMouseLeave)
  //   document.addEventListener('mouseenter', handleMouseEnter)
  //   window.addEventListener('blur', handleWindowBlur)
  //   window.addEventListener('focus', handleWindowFocus)

  //   // Remove os eventos ao desmontar o componente
  //   return () => {
  //     document.removeEventListener('mouseleave', handleMouseLeave)
  //     document.removeEventListener('mouseenter', handleMouseEnter)
  //     window.removeEventListener('blur', handleWindowBlur)
  //     window.removeEventListener('focus', handleWindowFocus)
  //   }
  // }, [])

  // useEffect(() => {
  //   const handleMouseLeave = (e: any) => {
  //     if (
  //       e.clientY <= 0 ||
  //       e.clientX <= 0 ||
  //       e.clientX >= window.innerWidth ||
  //       e.clientY >= window.innerHeight
  //     ) {
  //       setIsMouseOutside(true)
  //     }
  //   }

  //   const handleMouseEnter = () => {
  //     setIsMouseOutside(false)
  //   }

  //   document.addEventListener('mouseleave', handleMouseLeave)
  //   document.addEventListener('mouseenter', handleMouseEnter)

  //   return () => {
  //     document.removeEventListener('mouseleave', handleMouseLeave)
  //     document.removeEventListener('mouseenter', handleMouseEnter)
  //   }
  // }, [])

  useEffect(() => {
    const disableContextMenu = (e: MouseEvent): void => e.preventDefault()
    window.addEventListener('contextmenu', disableContextMenu)

    return () => {
      window.removeEventListener('contextmenu', disableContextMenu)
    }
  }, [])

  useEffect(() => {
    const disableKeyShortcuts = (e: any) => {
      if (
        (e.ctrlKey && e.key === 'c') || // Ctrl+C
        (e.ctrlKey && e.key === 'v') || // Ctrl+V
        (e.ctrlKey && e.key === 's') || // Ctrl+S
        (e.ctrlKey && e.key === 'p') || // Ctrl+P
        (e.ctrlKey && e.key === 'u') || // Ctrl+U (Ver código fonte)
        e.key === 'F12' || // F12 (DevTools)
        (e.ctrlKey && e.shiftKey && e.key === 'I') // Ctrl+Shift+I
      ) {
        e.preventDefault()
      }
    }

    window.addEventListener('keydown', disableKeyShortcuts)

    return () => {
      window.removeEventListener('keydown', disableKeyShortcuts)
    }
  }, [])

  if (isMouseOutside) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          zIndex: 9999,
          pointerEvents: 'none'
        }}
      ></div>
    )
  }

  if (isScreenDimmed) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          zIndex: 9999,
          pointerEvents: 'none'
        }}
      ></div>
    )
  }

  return (
    <Router>
      <LayoutProvider>
        <AppProvider>
          <PageDataProvider>
            <QueryParamProvider ReactRouterRoute={Route}>
              <MasterInit />
              <GlobalStyle />
              <Routes />
            </QueryParamProvider>
          </PageDataProvider>
        </AppProvider>
      </LayoutProvider>
    </Router>
  )
}

export default App
