import React from 'react'
import Container from '../../../../components/Container'
import { FormQueryBatchImport } from '../components/FormImport'
import { nameActions } from '../domain/info'
import { breadcrumbCreate } from '../domain/breadcrumb'
import { toolsCreate } from '../domain/tools/create'

const CreateImport = (): JSX.Element => (
  <Container
    pageTitle={nameActions.create.name}
    breadcrumb={breadcrumbCreate}
    tools={toolsCreate}
  >
    <FormQueryBatchImport typeForm="create" />
  </Container>
)

export default CreateImport
