import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { PageLink, PageTitle } from '../../assets/layout/core'
import { Card } from 'components/Card'
import { useToast } from 'hooks/toast'
import api from 'services/api'
import moment from 'moment'
import { MANAGER_ROLES, MASKS, ROLE_PARTNER_TEAM } from 'common/constants'
import { useAuth } from 'hooks/auth'
import Form, { Input } from 'components/Form'
import { handleErrorMessage } from 'utlis/handleErros'
import { cellPhoneMask, cnpjMask, cpfMask } from 'utlis/mask'
import {
  LEMIT_COLUMN_NAME_DDD,
  LEMIT_COLUMN_NAME_NUMBER,
  LEMIT_FIELD_PHONE
} from 'common/constants/Commercial/Prospects'
import { Loading } from 'components/Loading'
import DataTable from 'components/DataTable'
import LemitDataDisplay from 'components/LemitInfo'
import { useUpdateDataTable } from 'hooks/dataTable'

const dashboardBreadCrumbs: PageLink[] = [
  {
    title: 'Início',
    path: '/Tela Inícial',
    isSeparator: false,
    isActive: false
  }
]

async function handleVerifyIfNumberHasDoNotDisturb({
  number
}: {
  number: string
}) {
  try {
    const responseDoNotDisturb = await api.post(
      'https://api.cedibra.com/commercial/DoNotDisturb/findByParams',
      {
        phone: number
      }
    )
    return responseDoNotDisturb.data.doNotDisturb
  } catch (error) {}
}
const Dashboard: React.FC = () => {
  const { user } = useAuth()
  const { addToast } = useToast()
  const { updateDataTable } = useUpdateDataTable()
  const [isLoading, setIsLoading] = useState(false)
  const [distributed, setDistributed] = useState('0')
  const [scheduleToday, setScheduleToday] = useState('0')
  const startDay = moment().startOf('day').format(MASKS.DATE.ISO)
  const endDay = moment().endOf('day').format(MASKS.DATE.ISO)
  const [proposals, setProposals] = useState('0')
  const [lemitData, setLemitData] = useState<any[]>([])
  const elementsRef = useRef<any>({})
  const [apisQuery, setApisQuery] = useState<GetCountPerApiParams[]>([
    {
      api_name: 'Lemit',
      quantity: '0'
    },
    {
      api_name: 'Credcesta',
      quantity: '0'
    },
    {
      api_name: 'Fgts',
      quantity: '0'
    },
    {
      api_name: 'Sim',
      quantity: '0'
    }
  ])

  const handleLoadProposals = useCallback(async () => {
    try {
      const responseProposals = await api.post(
        '/commercial/proposals/countProposal'
      )
      setProposals(responseProposals.data.proposals)
    } catch (error) {
      addToast({
        title: 'Erro ao carregar dados',
        description: 'Ocorreu um erro ao carregar os dados',
        type: 'error'
      })
    }
  }, [addToast])
  const handleLoadDistributed = useCallback(async () => {
    try {
      const responseDistributed = await api.post(
        '/commercial/prospectPartners/distributed'
      )
      setDistributed(responseDistributed.data)
    } catch (error) {
      addToast({
        title: 'Erro ao carregar dados',
        description: 'Ocorreu um erro ao carregar os dados',
        type: 'error'
      })
    }
  }, [addToast])

  const handleLoadApisQuery = useCallback(async () => {
    try {
      const responseApisQuery = await api.post(
        '/commercial/prospectsQueriesApis/getCountPerApi'
      )
      setApisQuery(oldApisQuery =>
        oldApisQuery.map(oldApiQuery => {
          const findApiQuery = responseApisQuery.data.find(
            (item: GetCountPerApiParams) =>
              oldApiQuery.api_name.toLocaleLowerCase() ===
              item.api_name.toLocaleLowerCase()
          )
          if (findApiQuery) {
            return {
              ...findApiQuery
            }
          }
          return oldApiQuery
        })
      )
    } catch (error) {
      addToast({
        title: 'Erro ao carregar dados',
        description: 'Ocorreu um erro ao carregar os dados',
        type: 'error'
      })
    }
  }, [addToast])

  const handleLoadScheduleToday = useCallback(async () => {
    try {
      const responseSchedules = await api.post(
        '/commercial/prospectHistories/schedule'
      )
      setScheduleToday(responseSchedules.data)
    } catch (error) {
      addToast({
        title: 'Erro ao carregar dados',
        description: 'Ocorreu um erro ao carregar os dados',
        type: 'error'
      })
    }
  }, [addToast])

  useEffect(() => {
    if (user?.role?.team === ROLE_PARTNER_TEAM) {
      handleLoadScheduleToday()
    }
    if (user?.role?.team !== ROLE_PARTNER_TEAM) {
      handleLoadProposals()
      handleLoadDistributed()
      handleLoadApisQuery()
    }
  }, [
    handleLoadApisQuery,
    handleLoadDistributed,
    handleLoadProposals,
    handleLoadScheduleToday,
    user?.role?.team
  ])

  useEffect(() => {
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])
  const dateOutFormat = 'DD/MM/YYYY'
  const formatsAvailable = useMemo(
    () => [
      'YYYY-MM-DD',
      'YYYY-MM-DD HH:mm:ss',
      'DD/MM/YYYY',
      'DD/MM/YYYY HH:mm:ss',
      'YYYY-MM-DDTHH:mm:ssZ',
      'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'
    ],
    []
  )

  const checkFormatDates = useCallback(
    (value: string) => {
      // eslint-disable-next-line consistent-return
      let formatValue = 'YYYY-MM-DD'
      formatsAvailable.forEach(format => {
        if (moment(value, format, true).isValid()) {
          formatValue = format
        }
      })
      return formatValue
    },
    [formatsAvailable]
  )
  const formatingValues = useCallback(
    ({ key, value: inputValue }: { key: string; value: string | number }) => {
      const fieldsFormating = ['cpf', 'cnpj']
      const value = inputValue.toString()
      const findField = fieldsFormating.find(item => key.includes(item))
      if (inputValue.toString().match(/[0-9]/gi)) {
        if (findField) {
          if (findField === 'cpf') {
            return cpfMask(value)
          }
          if (findField === 'cnpj') {
            return cnpjMask(value)
          }
        }
      }
      if (value === 'true') {
        return 'Sim'
      }
      if (value === 'false') {
        return 'Não'
      }
      const format = checkFormatDates(value)
      if (moment(value, format, true).isValid()) {
        return moment(value, format).format(dateOutFormat)
      }
      return value
    },
    [checkFormatDates]
  )
  const serializedKey = useCallback((value: string) => {
    return value.split('_').join(' ')
  }, [])
  const renderElement = useCallback(
    ({ item, parentKey, parentData }): any => {
      if (item) {
        const dataEntries: any = Object.entries(item)
        for (const [key, value] of dataEntries) {
          if (typeof value === 'object') {
            if (Array.isArray(value)) {
              if (value.length) {
                renderElement({
                  parentData: value,
                  parentKey: serializedKey(key)
                })
              }
            } else {
              renderElement({ item: value, parentKey: serializedKey(key) })
            }
          } else {
            if (parentKey) {
              if (elementsRef.current[parentKey]) {
                elementsRef.current[parentKey] = {
                  ...elementsRef.current[parentKey],
                  child: [
                    ...elementsRef.current[parentKey].child,
                    {
                      title: serializedKey(key),

                      value: formatingValues({ key, value })
                    }
                  ]
                }
              } else {
                elementsRef.current[parentKey] = {
                  title: parentKey,

                  value: 'parent',
                  child: [
                    {
                      title: serializedKey(key),

                      value: formatingValues({ key, value })
                    }
                  ]
                }
              }
            } else {
              elementsRef.current[key] = {
                title: serializedKey(key),

                value: formatingValues({ key, value })
              }
            }
          }
        }
      } else if (parentData) {
        if (parentKey) {
          if (elementsRef.current[parentKey]) {
            elementsRef.current[parentKey] = {
              ...elementsRef.current[parentKey],
              type: 'array',
              child: [...elementsRef.current[parentKey].child, ...parentData]
            }
          } else {
            elementsRef.current[parentKey] = {
              title: parentKey,
              type: 'array',
              value: 'parent',
              child: [...parentData]
            }
          }
        }
      }
      return elementsRef.current
    },
    [formatingValues, serializedKey]
  )

  const onSubmitForm = useCallback(
    async ({ document }: { document: string }) => {
      elementsRef.current = {}
      setIsLoading(true)
      try {
        const responseLemit = await api.post('/apis/lemit/singleQuery', {
          document
        })
        const dataResultElements = renderElement({ item: responseLemit.data })
        const arrayElements = Object.values(dataResultElements) as any[]
        const findPhonesArray = arrayElements.findIndex(
          item => item.title.toLowerCase() === LEMIT_FIELD_PHONE
        )
        if (findPhonesArray !== -1) {
          const newPhoneArray = []
          for (const item of arrayElements[findPhonesArray].child) {
            const responseDoNotDisturb =
              await handleVerifyIfNumberHasDoNotDisturb({
                number: `${item?.[LEMIT_COLUMN_NAME_DDD]}${item?.[LEMIT_COLUMN_NAME_NUMBER]}`
              })
            newPhoneArray.push({ ...item, doNotDisturb: responseDoNotDisturb })
          }
          arrayElements[findPhonesArray].child = [...newPhoneArray]
        }
        updateDataTable()
        setLemitData(arrayElements)
        addToast({
          type: 'success',
          title: 'Consulta concluída com sucesso',
          description: 'Consulta concluída com sucesso'
        })
      } catch (error: any) {
        addToast({
          ...handleErrorMessage(error)
        })
      }
      setIsLoading(false)
    },
    [addToast, renderElement, updateDataTable]
  )

  const generateLemitData = useCallback(
    async ({ data }) => {
      const dataResultElements = renderElement({ item: data })
      const arrayElements = Object.values(dataResultElements) as any[]
      const findPhonesArray = arrayElements.findIndex(
        item => item.title.toLowerCase() === LEMIT_FIELD_PHONE
      )
      if (findPhonesArray !== -1) {
        const newPhoneArray = []
        for (const item of arrayElements[findPhonesArray].child) {
          const responseDoNotDisturb =
            await handleVerifyIfNumberHasDoNotDisturb({
              number: `${item?.[LEMIT_COLUMN_NAME_DDD]}${item?.[LEMIT_COLUMN_NAME_NUMBER]}`
            })
          newPhoneArray.push({ ...item, doNotDisturb: responseDoNotDisturb })
        }
        arrayElements[findPhonesArray].child = [...newPhoneArray]
      }
      return arrayElements
    },
    [renderElement]
  )

  return (
    <>
      <Loading isActive={isLoading} />
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>Tela Inícial</PageTitle>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '2rem 5rem',
          width: '100%'
        }}
      >
        {user?.role?.team === ROLE_PARTNER_TEAM && (
          <Card
            primaryColor="warning"
            text="Agendamentos do dia"
            number={scheduleToday}
            onClick={() => {
              const a = document.createElement('a')
              a.href = `/commercial/prospects?start_date=${startDay}&end_date=${endDay}`
              a.click()
              document.removeChild(a)
            }}
            style={{
              cursor: 'pointer'
            }}
            iconSettings={{
              icon: 'fa fa-calendar',
              style: {
                color: '#fff'
              }
            }}
          />
        )}
        {user?.role?.team !== ROLE_PARTNER_TEAM && (
          <>
            <Card
              primaryColor="success"
              text="Distribuídos"
              number={distributed}
              iconSettings={{
                icon: 'fa fa-paper-plane',
                style: {
                  color: '#fff'
                }
              }}
            />
            {apisQuery.map(item => {
              return (
                <Card
                  key={item.api_name}
                  primaryColor="success"
                  text={'Consultas ' + item.api_name}
                  number={item.quantity}
                  iconSettings={{
                    icon: 'fa fa-magnifying-glass',
                    style: {
                      color: '#fff'
                    }
                  }}
                />
              )
            })}
            <Card
              primaryColor="primary"
              text="Propostas aprovadas"
              number={proposals}
              iconSettings={{
                icon: 'fa fa-handshake',
                style: {
                  color: '#fff'
                }
              }}
            />
          </>
        )}
      </div>
      {!!(
        !MANAGER_ROLES.includes(user?.role_id) &&
        user?.partner?.crm_query_lemit &&
        user?.role?.team?.toLowerCase() === 'p'
      ) && (
        <div className="card mt-10">
          <div className="card-body p-9">
            <h2>Consultar no lemit</h2>
            <Form onSubmit={onSubmitForm}>
              <div className="mb-5">
                <Input
                  name="document"
                  label="CPF"
                  className="col-md-3"
                  controlled
                  mask={{ mask: MASKS.DOCUMENT.CPF, type: 'cpf' }}
                />
              </div>
              <div className="card-footer d-flex justify-content-end py-6 ps-9 pe-0">
                <button type="submit" className="btn btn-primary">
                  Consultar
                </button>
              </div>
            </Form>

            <div>
              <DataTable
                entity="ProspectQueryApiPartnerUsage"
                source="dashboard/prospectQueryApiPartnerUsage"
                format={{ orderBy: 'id' }}
                notHasChildren
                headers={[
                  {
                    name: 'CPF',
                    field: 'result.cpf',
                    sortable: true,
                    custom: true
                  },
                  {
                    name: 'Nome',
                    field: 'result.name',
                    sortable: true,
                    custom: true
                  },
                  {
                    name: 'Celular 1',
                    field: 'result.phone_1',
                    sortable: true,
                    custom: true
                  },
                  {
                    name: 'Celular 2',
                    field: 'result.phone_2',
                    sortable: true,
                    custom: true
                  },
                  {
                    name: 'Celular 3',
                    field: 'result.phone_3',
                    sortable: true,
                    custom: true
                  },
                  {
                    name: 'Ações',
                    field: 'actions',
                    sortable: true
                  }
                ]}
                hasSearchInput={false}
                customHeaders={[
                  {
                    name: 'CPF',
                    field: 'result.cpf',
                    sortable: true,
                    element: item => {
                      return (
                        <p>
                          {cpfMask(item?.prospectQueryApi?.result?.pessoa?.cpf)}
                        </p>
                      )
                    }
                  },
                  {
                    name: 'Nome',
                    field: 'result.name',
                    sortable: true,
                    element: item => {
                      return (
                        <p>{item?.prospectQueryApi?.result?.pessoa?.nome}</p>
                      )
                    }
                  },
                  {
                    name: 'Celular 1',
                    field: 'result.phone_1',
                    sortable: true,
                    element: item => {
                      const data =
                        item?.prospectQueryApi?.result?.pessoa?.celulares?.[0]
                      return (
                        <p>{cellPhoneMask(`${data?.ddd}${data?.numero}`)}</p>
                      )
                    }
                  },
                  {
                    name: 'Celular 2',
                    field: 'result.phone_2',
                    sortable: true,
                    element: item => {
                      const data =
                        item?.prospectQueryApi?.result?.pessoa?.celulares?.[1]
                      return (
                        <p>{cellPhoneMask(`${data?.ddd}${data?.numero}`)}</p>
                      )
                    }
                  },
                  {
                    name: 'Celular 3',
                    field: 'result.phone_3',
                    sortable: true,
                    element: item => {
                      const data =
                        item?.prospectQueryApi?.result?.pessoa?.celulares?.[2]
                      return (
                        <p>{cellPhoneMask(`${data?.ddd}${data?.numero}`)}</p>
                      )
                    }
                  }
                ]}
                actions={[
                  {
                    name: 'view',
                    title: 'Visualizar',
                    spanIcon: 'fa fa-search',
                    onClick: async item => {
                      elementsRef.current = {}
                      setIsLoading(true)
                      if (item.prospectQueryApi.result) {
                        const data = await generateLemitData({
                          data: { ...item.prospectQueryApi.result }
                        })
                        setLemitData(data)
                        setIsLoading(false)
                      }
                    }
                  }
                ]}
              />
            </div>

            {lemitData && <LemitDataDisplay lemitData={lemitData} />}
          </div>
        </div>
      )}
    </>
  )
}

export default Dashboard
